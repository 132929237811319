import { CSSProperties, ForwardRefRenderFunction, ReactNode, forwardRef, useImperativeHandle, useState } from "react";
import { Card } from "react-bootstrap";

interface CardCSS extends CSSProperties {
  "--animate-duration": string;
}

export type ShakeBoxRef = { shake: () => void } | undefined;

const ShakeBoxBase: ForwardRefRenderFunction<
  ShakeBoxRef,
  {
    children: ReactNode;
    className: string;
  }
> = ({ children, className }, ref) => {
  const ms = 600;

  const [shakeAnimeStyle, setShakeAnimeStyle] = useState<string[]>([]);

  useImperativeHandle(ref, () => ({
    shake() {
      setShakeAnimeStyle(["animate__animated", "animate__shakeX"]);
      setTimeout(() => {
        setShakeAnimeStyle([]);
      }, ms);
    },
  }));

  const cardClasses = ["ShakeBox"];
  if (className) {
    cardClasses.push(...className.split(" "));
  }
  if (shakeAnimeStyle.length !== 0) {
    cardClasses.push(...shakeAnimeStyle);
  }

  return <Card className={cardClasses.join(" ")} style={{ "--animate-duration": `${ms / 1000}s` } as CardCSS}>{children}</Card>;
};

const ShakeBox = forwardRef(ShakeBoxBase);

export default ShakeBox;
import { faCircleUser, faLock, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { isAxiosError } from "axios";
import { ChangeEvent, FC, FocusEvent, KeyboardEvent, MutableRefObject, RefObject, createRef, useEffect, useRef, useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

import ShakeBox, { ShakeBoxRef } from "../../molecules/shake-box/ShakeBox";

import "./MFACodeInput.scss";

type Response = ResponseVerifiedCode | ResponseVerifiedSessionToken;

type ResponseVerifiedCode = {
  result: "verified_code";
  sessionToken: string;
}

type ResponseError = {
  client: {
    clientId: string;
    clientName: string;
    issur: string;
    issurName: string;
    loginUrl: string;
  };
}

type ResponseVerifiedSessionToken = {
  result: "verified_session_token";
  user: {
    email: string;
    issur: string;
  };
  client: {
    clientId: string;
    clientName: string;
    issur: string;
    issurName: string;
  };
}

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

let alreadyRedirect = false;
function redirect(url: string) {
  if (alreadyRedirect) {
    return;
  }
  window.location.href = url;
  alreadyRedirect = true;
}

const MFACodeInput: FC = () => {
  const [searchParams] = useSearchParams();
  const [ready, setReady] = useState(false);
  const [calledFocus, setCalledFocus] = useState(false);
  const [codes, setCodes] = useState(["", "", "", "", "", ""]);
  const [queries, setQueries] = useState<{ sessionToken?: string; redirectUrl?: string; state?: string; }>({});
  const [issurName, setIssurName] = useState("");
  const [clientName, setClientName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [hasError, setHasError] = useState(false);
  const shakeBoxRef = useRef<ShakeBoxRef>();
  const [faild, setFaild] = useState(false);
  const [loginUrl, setLoginUrl] = useState<string | undefined>(undefined);
  const inputRefs = useRef<RefObject<HTMLInputElement>[]>([]);

  for (const [idx] of Object.entries(codes)) {
    inputRefs.current[+idx] = createRef<HTMLInputElement>();
  }

  useEffect(() => {
    document.body.classList.add("MFACodeInputBody");
    if (!ready) {
      document.body.classList.add("loading");
    } else {
      document.body.classList.remove("loading");
    }
    return () => {
      document.body.classList.remove("MFACodeInputBody");
      document.body.classList.remove("loading");
    }
  }, [ready]);

  useEffect(() => {
    const sessionToken = searchParams.get("session_token") || undefined;
    const redirectUrl = searchParams.get("redirect_url") || undefined;
    const state = searchParams.get("state") || undefined;
    setQueries({ sessionToken, redirectUrl, state });
    (async () => {
      const { data } = await axios.post<Response>("/api/mfa/verify", { sessionToken, state });
      // console.log(data);
      if (data.result === "verified_session_token") {
        setIssurName(data.client.issurName);
        setClientName(data.client.clientName);
        setUserEmail(data.user.email);
        setReady(true);
      } else {
        redirect(`${redirectUrl}?session_token=${data.sessionToken}&state=${state}`);
      }
    })().catch(e => {
      if (isAxiosError<ResponseError>(e) && e.response) {
        if (e.response.status === 401) {
          setReady(true);
          setFaild(true);
          setIssurName(e.response.data.client.issurName);
          setClientName(e.response.data.client.clientName);
          setLoginUrl(e.response.data.client.loginUrl);
        }
      }
    });
  }, [searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (calledFocus) {
      return;
    }
    if (!inputRefs.current[0].current) {
      return;
    }
    inputRefs.current[0].current?.focus();
    setCalledFocus(true);
  });

  const handleSubmit = async (codes: string[]) => {
    try {
      const { sessionToken, state, redirectUrl } = queries;
      const { data } = await axios.post<ResponseVerifiedCode>("/api/mfa/verify", { code: codes.join(""), sessionToken, state });
      setHasError(false);
      redirect(`${redirectUrl}?session_token=${data.sessionToken}&state=${state}`);
    } catch (e) {
      if (isAxiosError<ResponseError>(e) && e.response?.status === 401) {
        setFaild(true);
        setLoginUrl(e.response.data.client.loginUrl);
        return;
      }
      if (!hasError) {
        setHasError(true);
      }
      shakeBoxRef.current?.shake();
      inputRefs.current[0].current?.focus();
    }
  };

  const handleChange = (e: ChangeEvent<FormControlElement>, idx: number, refs: MutableRefObject<RefObject<HTMLInputElement>[]>) => {
    const tmpCodes = [...codes];
    if (idx === 0 && e.target.value.length === 6) {
      (e.target.value as string).split("").forEach((val, idx) => {
        tmpCodes[idx] = val;
      });
      setCodes(tmpCodes);
      inputRefs.current[idx].current?.blur();
      handleSubmit(tmpCodes);
      return;
    }

    tmpCodes[idx] = e.target.value.slice(-1);
    setCodes(tmpCodes);
    if (idx < (inputRefs.current.length - 1)) {
      inputRefs.current[idx + 1].current?.focus();
    } else {
      inputRefs.current[idx].current?.blur();
      handleSubmit(tmpCodes);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<FormControlElement>, idx: number) => {
    if (e.key === "Backspace" && idx !== 0) {
      inputRefs.current[idx - 1].current?.focus();
    }
  }

  const handleFocus = (_e: FocusEvent<FormControlElement, Element>, idx: number) => {
    setCodes(codes => {
      const tmpCodes = [...codes];
      for (let i = idx; i < codes.length; i++) {
        tmpCodes[i] = "";
      }
      return tmpCodes;
    });
  }

  if (!ready) {
    return (
      <div className="MFACodeInput d-flex align-items-center">
        <div className="container text-center">
          <Spinner animation="border" variant="secondary" className="loading">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }

  if (faild) {
    return (
      <div className="MFACodeInput d-flex align-items-center">
        <div className="container">
          <Card className="box text-center">
            <FontAwesomeIcon icon={faTriangleExclamation} className="mb-2" />
            <h3 className="mb-1 small text-muted">{issurName}</h3>
            <h2 className="h4 mb-3">{clientName}</h2>
            <h1 className="h4 mb-4">2段階認証エラー</h1>
            {loginUrl ? (
              <>
                <p className="small text-muted mb-4">
                  2段階認証でエラーが発生しました。<br />
                  再度ログイン画面から開始してください。
                </p>
                <div>
                  <Button variant="secondary" onClick={() => {
                    redirect(loginUrl);
                  }}>再度ログイン</Button>
                </div>
              </>
            ) : (
              <p className="small text-muted mb-4">
                2段階認証でエラーが発生しました。<br />
                本画面を閉じて、再度Webアプリの<br />
                ログイン画面から開始してください。
              </p>
            )}
          </Card>
        </div>
      </div>
    );
  }

  return (
    <div className="MFACodeInput d-flex align-items-center">
      <div className="container">
        <ShakeBox className="box text-center" ref={shakeBoxRef} >
          <FontAwesomeIcon icon={faLock} className="mb-2" />
          <h3 className="mb-1 small text-muted">{issurName}</h3>
          <h2 className="h4 mb-3">{clientName}</h2>
          <h1 className="h4 mb-4">2段階認証プロセス</h1>
          <p className="small text-muted">この手順によりログインしようとしているのが<br />ご自身であることを証明します。</p>
          <div className="d-flex align-items-center justify-content-center gap-1 mb-4 text-muted">
            <div className="mt-1"><FontAwesomeIcon icon={faCircleUser} /></div>
            <div>{userEmail}</div>
          </div>
          <p className="small mb-4 text-muted"><b>認証システムアプリ</b>から確認コードを取得する</p>
          <Form noValidate>
            <Form.Group className="mb-3" controlId="codes">
              <Form.Label className="d-none">認証コード</Form.Label>
              <div className={`d-flex gap-2 input-code${hasError ? " is-invalid" : ""}`}>
                {inputRefs.current.map((ref, idx) => (
                  <Form.Control
                    key={idx}
                    type="tel"
                    className={`text-center fs-4${hasError ? " is-invalid" : ""}`}
                    ref={ref}
                    value={codes[idx]}
                    onFocus={(e) => handleFocus(e, idx)}
                    onKeyDown={(e) => handleKeyDown(e, idx)}
                    onChange={(e) => handleChange(e, idx, inputRefs)}
                  />
                ))}
              </div>
              <Form.Control.Feedback type="invalid">
                コードに誤りがあります。
              </Form.Control.Feedback>
              {/* <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text> */}
            </Form.Group>
          </Form>
        </ShakeBox>
      </div>
    </div>
  );
}

export default MFACodeInput;
